export const PRICE_YEARLY_NOT_SUBSCRIBED_LEGACY = 329.89;
export const PRICE_YEARLY_DEFAULT_LEGACY = 359.88;

export const variables = () => ([
  {
    name: "PRICE_YEARLY_NOT_SUBSCRIBED_LEGACY",
    value: PRICE_YEARLY_NOT_SUBSCRIBED_LEGACY.toFixed(2),
  },
  {
    name: "PRICE_YEARLY_DEFAULT_LEGACY",
    value: PRICE_YEARLY_DEFAULT_LEGACY.toFixed(2),
  },
]);
