function onKeyDownListener(e) {
  if (e.keyCode === 27 && this.vnode && this.vnode.componentInstance.is_open) {
    this.vnode.componentInstance.close();
  }
}
const registerDirective = Vue => {
  Vue.directive("sweet-esc", {
    bind: (el, __, vnode) => {
      el.vnode = vnode;
      el.binded = onKeyDownListener.bind(el);
      document.addEventListener("keydown", el.binded);
    },
    unbind: el => {
      document.removeEventListener("keydown", el.binded);
    }
  });
};

export default registerDirective;
